<template>
  <v-container fluid>
    <v-tabs v-model="generaltab" background-color="grey lighten-2">
      <v-tab key="wartung">Wartungen</v-tab>
      <v-tab key="price">Preisliste ({{ pricelist.length }})</v-tab>
      <v-tab key="material">Materialliste ({{ materiallist.length }})</v-tab>
    </v-tabs>
    <v-layout wrap>
      <v-tabs-items v-model="generaltab" class="full-width ma-2">
        <v-tab-item key="wartung">
          <v-card>
            <v-card-title
              >Wartungs-Administration
              <v-btn class="success float-right" @click="newGroup = true">
                <v-icon>add</v-icon>Neue Gruppe erstellen
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-flex sm="12" md="10" lg="10">
                <v-tabs
                  v-model="tab"
                  dark
                  background-color="blue-grey darken-1"
                >
                  <v-tab
                    v-for="(group, i) in groups"
                    :key="i"
                    @click="changeTab(i)"
                    >{{ group.name }}</v-tab
                  >
                </v-tabs>
              </v-flex>
              <v-flex sm="12">
                <v-list-item three-line>
                  <v-list-item-content v-if="!groups || !groups.length">
                    Sie haben noch keine Gruppe angelegt. Bitte legen Sie
                    zunächst eine Gruppe an um die Konfiguration zu starten.
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-title class="headline mb-1">
                      <v-flex xs10 sm9 md8>
                        <v-text-field
                          label="Gruppenname"
                          @keyup="changes = true"
                          v-model="group.name"
                        ></v-text-field>
                      </v-flex>
                      <v-btn
                        class="float-right"
                        color="success"
                        text
                        @click="
                          newStructureGroupIndex = i;
                          newStructure = true;
                        "
                      >
                        <i class="material-icons">add</i>Neue Struktur erstellen
                      </v-btn>
                    </v-list-item-title>
                    <v-expansion-panels multiple accordion v-model="panels">
                      <v-expansion-panel
                        v-for="(item, j) in group.structure"
                        :key="j"
                      >
                        <v-expansion-panel-header>
                          <v-flex xs4 sm3 md2>
                            <v-text-field
                              outlined
                              label="Strukturnummer"
                              @keyup="changes = true"
                              v-model="item.number"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs7 sm7 md5>
                            <v-text-field
                              outlined
                              label="Strukturname"
                              @keyup="changes = true"
                              v-model="item.name"
                            ></v-text-field>
                          </v-flex>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="card my-4">
                            <!-- <v-data-table
                            :headers="headers"
                            :items="item.items"
                            hide-default-footer
                            class="elevation-1"
                          >
                            <template v-slot:item.number="{ item }">
                              <v-text-field  v-model="item.number" ></v-text-field>
                            </template>
                              <template v-slot:item.name="{ item }">
                              <v-text-field  v-model="item.name" ></v-text-field>
                            </template>
                              <template v-slot:item.measurement="{ item }" class="text-center">
                                <v-checkbox
                                    v-model="item.measurement"
                                    @change="changes = true"
                                  ></v-checkbox>
                            </template>
                              <template v-slot:item.action="{ item }">
                              <v-btn class="error" small fab @click="removeItem(i,j,k)">
                                    <i class="material-icons">remove</i>
                                  </v-btn>
                            </template>
                          </v-data-table>
                          -->
                            <ul>
                              <li v-for="(entry, k) in item.items" :key="k">
                                <v-layout wrap>
                                  <v-flex xs3 sm2 md2>
                                    <v-text-field
                                      v-model="entry.number"
                                      label="Nummer"
                                      @keyup="changes = true"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs9 sm5 md7>
                                    <v-text-field
                                      v-model="entry.name"
                                      label="Bezeichnung"
                                      @keyup="changes = true"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs6 sm3 md2>
                                    <v-select
                                      :items="measurements"
                                      clearable
                                      item-value="value"
                                      item-text="text"
                                      @change="changes = true"
                                      v-model="entry.measurement"
                                      label="Messung"
                                    ></v-select>
                                    <!--  <v-checkbox
                                    v-model="entry.measurement"
                                    @change="changes = true"
                                    label="Messung erforderlich?"
                                  ></v-checkbox>-->
                                  </v-flex>
                                  <v-flex xs3 sm2 md1>
                                    <v-tooltip left>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          class="error"
                                          x-small
                                          fab
                                          @click="removeItem(i, j, k)"
                                        >
                                          <i class="material-icons">delete</i>
                                        </v-btn>
                                      </template>
                                      <span>Wartungsschritt löschen</span>
                                    </v-tooltip>
                                  </v-flex>
                                </v-layout>
                              </li>
                            </ul>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  class="success"
                                  small
                                  fab
                                  @click="addItem(i, j)"
                                >
                                  <i class="material-icons">add</i>
                                </v-btn>
                              </template>
                              <span>Wartungsschritt hinzufügen</span>
                            </v-tooltip>

                            <v-col cols="12" sm="12" md="12" lg="12">
                              <v-btn
                                class="float-right"
                                color="error"
                                text
                                @click="removeStructure(i, j)"
                              >
                                <v-icon>remove</v-icon>Struktur löschen
                              </v-btn>
                            </v-col>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-btn color="error" text @click="confirmRemoveGroup(i)">
                        <v-icon>remove</v-icon>Gruppe löschen
                      </v-btn>
                    </v-col>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-card-text>
          </v-card>
          <v-btn class="success mt-4" @click="saveChanges()"
            >Änderungen speichern</v-btn
          >
        </v-tab-item>
        <v-tab-item key="price">
          <v-card class="mx-auto" flat>
            <v-card-text>
              <v-data-table
                :headers="headersprices"
                height="inherit"
                hide-default-footer
                :items-per-page="-1"
                :items="pricelist"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title
                      >Preisliste ({{ pricelist.length }})</v-toolbar-title
                    >
                    <v-spacer></v-spacer>

                    <v-btn
                      fab
                      small
                      color="success"
                      @click="priceDialog = true"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                    <!--  <input type="file" @change="onFileChange" /> -->
                  </v-toolbar>
                </template>
                <template v-slot:item.price="{ item }">
                  <v-chip color="secondary" outlined>{{
                    $formatCurrency(item.price)
                  }}</v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                  <div class="text-center inline-flex">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="deletePriceItem(item.id)"
                          color="error"
                          small
                          fab
                        >
                          <i class="material-icons">delete</i>
                        </v-btn>
                      </template>
                      <span>Eintrag löschen</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="success" @click="updatePrices()"
                >Preisliste aktualisieren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item key="material">
          <v-card>
            <v-card-title>Materialliste</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headersmaterials"
                height="inherit"
                hide-default-footer
                :items-per-page="-1"
                :items="materiallist"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title
                      >Materialliste ({{
                        materiallist.length
                      }})</v-toolbar-title
                    >
                    <v-spacer></v-spacer>

                    <v-btn
                      fab
                      small
                      color="success"
                      @click="materialDialog = true"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.price="{ item }">
                  <v-chip color="secondary" outlined>{{
                    $formatCurrency(item.price)
                  }}</v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                  <div class="text-center inline-flex">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="deleteMaterialItem(item.id)"
                          color="error"
                          small
                          fab
                        >
                          <i class="material-icons">delete</i>
                        </v-btn>
                      </template>
                      <span>Eintrag löschen</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                :disabled="disableMaterialBtn"
                @click="updateMaterials()"
                >Materialliste aktualisieren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="newGroup" persistent max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline">Neue Gruppe erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="newGroupName"
                    label="Name*"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="addNewGroup()">Anlegen</v-btn>
            <v-btn text color="error" @click="newGroup = false"
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="priceDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Preis-Eintrag erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form2">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newPrice.name"
                      label="Bezeichnung*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newPrice.productid"
                      label="Produkt ID*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newPrice.category"
                      label="Kategorie"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      type="number"
                      v-model="newPrice.price"
                      label="Preis in €*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createNewPrice()"
              >Speichern</v-btn
            >
            <v-btn color="error" text @click="priceDialog = false"
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="newStructure" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Neue Struktur erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs3 sm3 md3>
                  <v-text-field
                    v-model="newStructureNumber"
                    label="Nummer*"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs9 sm9 md9>
                  <v-text-field
                    v-model="newStructureName"
                    label="Bezeichnung*"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="addStructure()"
              >Struktur Anlegen</v-btn
            >
            <v-btn color="error" text @click="newStructure = false"
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="materialDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Material-Eintrag erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form2">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newMaterial.name"
                      label="Name*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newMaterial.materialid"
                      label="Material ID*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newMaterial.description"
                      label="Beschreibung"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      type="number"
                      v-model="newMaterial.price"
                      label="Preis in €*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createNewMatrial()"
              >Speichern</v-btn
            >
            <v-btn color="error" text @click="materialDialog = false"
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify="center">
      <v-dialog v-model="confirm" persistent max-width="600">
        <v-card>
          <v-card-title class="headline error">Gruppe löschen</v-card-title>
          <v-card-text>
            <div class="ma-3 bold">
              Sind sie sicher, dass Sie die Gruppe unwiederruflich löschen
              wollen?
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="removeGroup(deleteGroupIndex)"
              >Ja</v-btn
            >
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tab: "",
      generaltab: 0,
      panels: [0],
      pricelist: [],
      materiallist: [],
      materialDialog: false,
      group: {},
      priceDialog: false,
      newGroup: false,
      newItem: false,
      newStructure: false,
      deleteGroupIndex: -1,
      confirm: false,
      newGroupName: "",
      newStructureName: "",
      newStructureNumber: "",
      newStructureGroupIndex: 0,
      groups: [],
      newPrice: {
        name: "",
        productid: "",
        price: "",
        category: "",
      },
      newMaterial: {
        name: "",
        price: "",
        description: "",
        materialid: "",
      },
      changes: false,
      headersprices: [
        {
          text: "Bezeichnung",
          align: "left",
          value: "name",
        },
        {
          text: "Produkt ID",
          align: "left",
          value: "productid",
        },
        {
          text: "Kategorie",
          align: "left",
          value: "category",
        },
        {
          text: "Preis in €",
          align: "left",
          value: "price",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersmaterials: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Material ID",
          align: "left",
          value: "materialid",
        },
        {
          text: "Beschreibung",
          align: "left",
          sortable: false,
          value: "description",
        },
        {
          text: "Preis in €",
          align: "left",
          value: "price",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      measurements: [
        {
          value: "1",
          text: "Soll/Ist-Wert Messung",
        },
        {
          value: "2",
          text: "Ist-Wert Messung",
        },
      ],
      headers: [
        {
          text: "Nummer",
          align: "left",
          sortable: false,
          value: "number",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Messung erforderlich",
          align: "center",
          sortable: false,
          value: "measurement",
        },
        {
          text: "Aktion",
          align: "center",
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    this.getStructure();
    this.getConfig();
  },
  methods: {
    changeTab(i) {
      this.group = this.groups[i];
    },
    addNewGroup() {
      this.groups.push({
        name: this.newGroupName,
        structure: [],
      });
      this.newGroup = false;
      this.newGroupName = "";
      const newGroupIndex = this.groups.length - 1;
      this.tab = newGroupIndex;
      this.group = this.groups[newGroupIndex];
    },
    addItem(i, j) {
      this.changes = true;
      this.groups[this.tab].structure[j].items.push({
        number: "",
        name: "",
        measurement: "-",
      });
    },
    removeItem(i, j, k) {
      this.changes = true;
      let items = this.groups[this.tab].structure[j].items;
      items.splice(k, 1);
    },
    removeGroup(i) {
      this.groups.splice(this.tab, 1);
      this.changes = true;
      this.deleteGroupIndex = -1;
      this.confirm = false;

      if (this.groups.length) {
        this.tab = 0;
        this.group = this.groups[0];
      }
    },
    confirmRemoveGroup(i) {
      this.deleteGroupIndex = i;
      this.confirm = true;
    },
    deletePriceItem(id) {
      this.pricelist = this.pricelist.filter((b) => {
        return b.id != id;
      });
    },
    deleteMaterialItem(id) {
      this.materiallist = this.materiallist.filter((b) => {
        return b.id != id;
      });
    },
    updateMaterials() {
      let formData = new FormData();
      formData.append("materiallist", JSON.stringify(this.materiallist));
      this.$http({
        method: "post",
        url: "editCompanyMateriallist.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Die Materialliste wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.disableMaterialBtn = true;
          } else {
            const msg =
              "Die Materialliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Die Materialliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    updatePrices() {
      let formData = new FormData();
      formData.append("pricelist", JSON.stringify(this.pricelist));
      this.$http({
        method: "post",
        url: "editCompanyPricelist.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Die Preisliste wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.disablePriceBtn = true;
          } else {
            const msg =
              "Die Preisliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Die Preisliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    createNewPrice() {
      if (!this.$refs.form2.validate()) {
        return;
      }
      const newPrice = JSON.parse(JSON.stringify(this.newPrice));
      newPrice.id = new Date().getTime();
      this.pricelist.push(newPrice);
      this.newPrice = {
        productid: "",
        name: "",
        price: "",
        category: "",
      };
      this.priceDialog = false;
    },

    createNewMatrial() {
      if (!this.$refs.form2.validate()) {
        return;
      }
      const newMaterial = JSON.parse(JSON.stringify(this.newMaterial));
      this.materiallist.push(newMaterial);
      this.newMaterial = {
        name: "",
        price: "",
        description: "",
        materialid: "",
      };
      this.disableMaterialBtn = false;
      this.materialDialog = false;
    },
    addStructure() {
      this.groups[this.tab].structure.push({
        items: [
          {
            number: "",
            name: "",
            measurement: "-",
          },
        ],
        number: this.newStructureNumber,
        name: this.newStructureName,
      });
      this.changes = true;
      this.newStructureNumber = "";
      this.newStructureName = "";
      this.newStructure = false;
    },
    removeStructure(i, j) {
      this.changes = true;
      this.groups[this.tab].structure.splice(j, 1);
    },
    getStructure() {
      this.$http({
        method: "post",
        url: "getWartungStructure.php",
      })
        /*  this.$http
        .get("/structure/structure") */
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 200 && response.data && response.data.data) {
            this.groups = response.data.data;
            if (this.groups.length) {
              this.tab = 0;
              this.changeTab(0);
            }
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          this.$router.push("/");
        });
    },
    getConfig() {
      this.$http({
        method: "post",
        url: "getCompanyConfig.php",
      })
        /*  this.$http
        .get("/structure/structure") */
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 200 && response.data && response.data.data) {
            const config = response.data.data;
            if (config.pricelist) {
              this.pricelist = JSON.parse(config.pricelist);
            }
            if (config.materiallist) {
              this.materiallist = JSON.parse(config.materiallist);
            }
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          this.$router.push("/");
        });
    },
    saveChanges() {
      /*   const groups = JSON.stringify(this.groups);
      const item = {
        groups: groups,
      };
      this.$http
        .post("/structure/savestructure", item) */
      let formData = new FormData();
      formData.append("groups", JSON.stringify(this.groups));
      this.$http({
        method: "post",
        url: "saveWartungStructure.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Ihre Änderungen wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Ihre Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Ihre Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
  },
};
</script>